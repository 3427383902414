<template>
  <layout>
    <!-- ============================================================== -->
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="page-breadcrumb">
      <div class="row align-items-center"></div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div style="color: #43bec7 !important" class="mr-2 mt-2">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="" method="post">
              <VueElementLoading
                :active="loading"
                spinner="ring"
                color="#42BFC7"
                :text="loadingText"
              />
              <div class="row mt-3">
                <div class="col-md-12 mb-3">
                  <label for="topic" style="color: black">Topic</label>
                  <textarea
                    v-model="form.topic"
                    name=""
                    id="topic"
                    cols="30"
                    rows="3"
                    class="form-control"
                  ></textarea>
                </div>
                <div class="col-md-12">
                  <label for="variant" style="color: black"
                    >Number of Variants</label
                  >
                  <div class="input-group">
                    <select
                      name=""
                      id="variant"
                      style="color: black"
                      class="form-control"
                      v-model="form.variant"
                    >
                      <option value="">--Select Variant--</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                    <div class="input-append">
                      <button
                        style="background: #43bec7; border: none"
                        type="button"
                        class="btn btn-primary caption-button"
                        @click.prevent="getCaption()"
                      >
                        Get Caption
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <nav class="tabs mb-4 align-items-center">
                    <div class="row mt-3">
                      <div class="col-md-6" v-for="(t, i) in tabs" :key="i">
                        <!-- active tab -->
                        <button
                          class="tabs__item"
                          @click.prevent="handleClick(`tab${i + 1}`)"
                        >
                          {{ t.title }}
                        </button>
                      </div>
                    </div>
                  </nav>
                  <div
                    class="font-weight-bold"
                    v-for="(t, i) in activeContent"
                    :key="i"
                  >
                    <div v-if="currentTab == 'tab' + (i + 1)">
                      <!-- <div class="12 text-dark fw-400">Update your personal details</div> -->
                      <div class="row">
                        <div class="col-md-12">
                          <!-- <h2>{{ t.Generated }}</h2> -->
                          <p class="generated-content">
                            {{ t.Generated }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    style="background:#44bdc7; border:1px #44bdc7;"
                    v-if="activeContent.length"
                    @click.prevent="saveContent()"
                  >
                    Save Content
                  </button>
          </div>
        </div>
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Sales chart -->
      <!-- ============================================================== -->
      <div class="row">
        <!-- Column -->
        <!-- Modal -->
        <div class="col-12 text-center h1 mb-3 fw-400">
          Traffic <span class="text-blue">Vault</span>
          <p class="blast">Share Your Website To 100 Free Traffic Sources</p>
          <button
            type="button"
            class="btn btn-blue caption-btn"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            Create a caption
          </button>
          <select name="" id="" class="form-control mt-4 caption-select" v-model="post">
          <VueElementLoading
                :active="loading"
                spinner="ring"
                color="#42BFC7"
                :text="loadingText"
              />
            <option value="1">Select a caption</option>
            <option :value="c.Content" v-for="(c,i) in contents" :key="i">{{ c.Content }}</option>
          </select>
        </div>

        <div class="col-12">
          <div class="row">
            <div
              class="col-12 col-lg-8 offset-lg-2 text-center"
              id="social-networks-wrap"
            >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.facebook.com/sharer/sharer.php?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/facebookimg copy.png"
                />Facebook</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.facebook.com/dialog/send?app_id=329829260786620&amp;link='+post"
              >
                <img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/facebook-messengerimg copy.png"
                />Facebook Messenger
              </a>
              <a
                class="share-btn"
                target="_blank"
                :href="'https://twitter.com/share?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/twitterimg copy.png"
                />Twitter</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://pinterest.com/pin/create/bookmarklet/?&amp;url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/pinterest-pinimg copy.png"
                />Pinterest</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://wa.me/?text='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/whatsappimg copy.png"
                />Whatsapp</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.viber.com/en/download/?text='+post"
                ><img
                  class="social-img"
                  src="https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Viber-256.png"
                />Viber</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://accounts.snapchat.com/accounts/login?client_id=geo'"
              >
                <img
                  class="social-img"
                  src="https://cdn-icons-png.flaticon.com/128/3670/3670166.png"
                />Snapchat
              </a>
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.linkedin.com/shareArticle?mini=true&amp;url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/linkedinimg copy.png"
                />Linkedin</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://reddit.com/submit?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/redditimg copy.png"
                />Reddit</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.tumblr.com/widgets/share/tool?canonicalUrl='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/tumblrimg copy.png"
                />Tumblr</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://mix.com/mixit?su=submit&amp;url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/mix.png"
                />Mix</a
              >
              <a class="share-btn" target="_blank" :href="'https://www.quora.com/'"
                ><img
                  class="social-img"
                  src="https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Quora-256.png"
                />Quora</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://global.nextdoor.com/'"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/nextdoor.png"
                />Nextdoor</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://foursquare.com/login'"
                ><img
                  class="social-img"
                  src="https://cdn0.iconfinder.com/data/icons/social-circle-3/72/Foursquare-256.png"
                />Foursquare</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://secure.meetup.com/login/'"
                ><img
                  class="social-img"
                  src="https://cdn1.iconfinder.com/data/icons/social-circle-2-1/72/MeetUp-256.png"
                />Meetup</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://deliciouskebabst5.com/login.php'"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/deliciousimg copy.png"
                />Delicious</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://digg.com/submit?phase=2&amp;url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/diggimg copy.png"
                />Digg</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.evernote.com/clip.action?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Evernote-256.png"
                />Evernote</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://share.flipboard.com/bookmarklet/popout?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/flipboardimg copy.png"
                />Flipboard</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://impif.com/sharer?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/impif.png"
                />Impif</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://www.meneame.net/submit.php?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/meneameimg copy.png"
                />Meneame</a
              >
              <a
                class="share-btn"
                target="_blank"
                href="threema://compose?text='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/threemaimg copy.png"
                />Threema</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&amp;st.shareUrl='+post"
              >
                <img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/odnoklassnikiimg copy.png"
                />Odnoklassniki
              </a>
              <a
                class="share-btn"
                target="_blank"
                :href="'http://service.weibo.com/share/share.php?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/sina-weiboimg copy.png"
                />Sina Weibo</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://vk.com/share.php?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/vkimg copy.png"
                />Vkontakte</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.blogger.com/blog_this.pyra?t&amp;n='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/bloggerimg copy.png"
                />Blogger</a
              >
              <a class="share-btn" target="_blank" :href="'mailto:?body='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/emailimg copy.png"
                />Email</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.xing.com/app/user?op=share&amp;url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/xingimg copy.png"
                />Xing</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://connect.mail.ru/share?share_url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/mail-ruimg copy.png"
                />Mail Ru</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://www.livejournal.com/update.bml?event='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/livejournalimg copy.png"
                />Livejournal</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://myspace.com/post?l=3&amp;u='+post"
                ><img
                  class="social-img"
                  src="https://cdn0.iconfinder.com/data/icons/free-social-media-set/24/myspace-256.png"
                />MySpace</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://buffer.com/add?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/bufferimg copy.png"
                />Buffer</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://www.douban.com/share/service?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/doubanimg copy.png"
                />Douban</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://b.hatena.ne.jp/bookmarklet?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/hatena.png"
                />Hatena</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.google.com/bookmarks/mark?op=edit&amp;bkmk='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/bookmarksimg copy.png"
                />Google Bookmarks</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://mail.google.com/mail/?view=cm&amp;to=&amp;body='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/gmailimg copy.png"
                />Gmail</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://news.ycombinator.com/submitlink?u='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/hackernewsimg copy.png"
                />HackerNews</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://www.instapaper.com/edit?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/instapaperimg copy.png"
                />Instapaper</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://social-plugins.line.me/lineit/share?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/lineimg copy.png"
                />Line</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://getpocket.com/edit?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/pocketimg copy.png"
                />Pocket</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://groupspaces.com/Q-Zone/login/?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/qzoneimg copy.png"
                />QZone</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://share.diasporafoundation.org/?title=Onboarding%20CMP%20-%20ShareThis&amp;url='+post"
              >
                <img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/diasporaimg copy.png"
                />Diaspora
              </a>
              <a
                class="share-btn"
                target="_blank"
                :href="'http://surfingbird.ru/share?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/surfingbirdimg copy.png"
                />Surfingbird</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://refind.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/refindimg copy.png"
                />Refind</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://widget.renren.com/dialog/share?resourceUrl='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/renrenimg copy.png"
                />RenRen</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://web.skype.com/share?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/skypeimg copy.png"
                />Skype</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://telegram.me/share/url?url='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/telegramimg copy.png"
                />Telegram</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://wordpress.com/log-in?redirect_to='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/wordpressimg copy.png"
                />WordPress</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://api.qrserver.com/v1/create-qr-code/?size=154x154&amp;data='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/wechatimg copy.png"
                />Wechat</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://compose.mail.yahoo.com/?body='+post"
                ><img
                  class="social-img"
                  src="https://pcdn.sharethis.com/wp-content/themes/sharethis-custom/assets/images/yahoomailimg copy.png"
                />Yahoo Mail</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://mixi.jp/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/mixi.png"
                />Mixi</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.amazon.com/gp/wishlist/static-add?u='+post"
                ><img
                  class="social-img"
                  src="https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Amazon-512.png"
                />Amazon</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://flattr.com/submit/auto?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn4.iconfinder.com/data/icons/miu-black-social-2/60/flattr-512.png"
                />Flattr</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://blog.naver.com/openapi/share?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn3.iconfinder.com/data/icons/address-book-providers-in-black-white/512/naver-2-512.png"
                />Naver</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://www.newsvine.com/_tools/seed&amp;save?u='+post"
                ><img
                  class="social-img"
                  src="https://cdn3.iconfinder.com/data/icons/social-media-chat-1/512/Newsvine-512.png"
                />Newsvine</a
              >
              <a
                class="share-btn"
                target="_blank"
                href="#"
                onclick="window.print();return false;"
              >
                <img
                  class="social-img"
                  src="https://cdn4.iconfinder.com/data/icons/ios-web-user-interface-multi-circle-flat-vol-5/512/Device_print_paper_technology_printing_printer-512.png"
                />Print
              </a>
              <a
                class="share-btn"
                target="_blank"
                :href="'https://partners.viadeo.com/share?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn0.iconfinder.com/data/icons/free-social-media-set/24/viadeo-512.png"
                />Viadeo</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://story.kakao.com/share?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/kakao.png"
                />Kakao</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://managewp.org/share/form?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/ManageWP.png"
                />ManageWP</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.wykop.pl/remotelink/?url='+post"
              >
                <img
                  class="social-img"
                  src="https://1.bp.blogspot.com/-Jdm-r5HPB2s/XJF7CCDVFQI/AAAAAAAAJPg/jNK6rSNU11ci2-YYJXH_PbAtbe0_2l69ACK4BGAYYCw/s1600/logo%2Bwykop%2Bvector.png"
                />Wykop
              </a>
              <a
                class="share-btn"
                target="_blank"
                :href="'https://secure.tagged.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn3.iconfinder.com/data/icons/social-media-chat-1/512/Tagged-512.png"
                />Tagged</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://badoo.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn0.iconfinder.com/data/icons/social-circle-3/72/Badoo-512.png"
                />Badoo</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.skyrock.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn-icons-png.flaticon.com/128/168/168791.png"
                />Skyrock</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'http://www.yummly.com/urb/verify?url='+post"
                ><img
                  class="social-img"
                  src="https://play-lh.googleusercontent.com/lq8cpjtN3gErseV08nIJYLCOzGnYkFI4FK7pztzHv3ETUK94kzA3psdPusx_IPMlOL6V"
                />Yummly</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.yammer.com/messages/new?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/yammer.png"
                />Yammer</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.cellufun.com/games.asp?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/cellufun.png"
                />Cellufun</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.wattpad.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/wattpad.png"
                />Wattpad</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.crunchyroll.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/crunchyroll.png"
                />Crunchyroll</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.caringbridge.org/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/caringbridge.png"
                />CaringBridge</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://steemit.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/steemit.png"
                />Steemit</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.caffeine.tv/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/caffeine.png"
                />Caffeine</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://houseparty.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/houseparty.png"
                />HouseParty</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://valence.community/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/valence.png"
                />Valence</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.pop-base.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/pop_base.png"
                />PopBase</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://yubo.live/en?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/yubo.png"
                />Yubo</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://elpha.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/elpha.png"
                />Elpha</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.triller.co/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/triller.png"
                />Triller</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://wt.social/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/WT Social.png"
                />WT Social</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://cafemom.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/cafemom.png"
                />CafeMom</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://discord.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/discord.png"
                />Discord</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://medium.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn4.iconfinder.com/data/icons/social-media-circle-7/512/Medium_circle-512.png"
                />Medium</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://ello.co/?url='+post"
                ><img
                  class="social-img"
                  src="https://upload.wikimedia.org/wikipedia/commons/7/76/Ello-logo.png"
                />Ello</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.twitch.tv/?url='+post"
                ><img
                  class="social-img"
                  src="https://pngimg.com/uploads/twitch/twitch_PNG28.png"
                />Twitch</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.goodreads.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn2.iconfinder.com/data/icons/goodreads-1/512/goodreads-round-light-1-512.png"
                />Goodreads</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.peanut-app.io/?url='+post"
                ><img
                  class="social-img"
                  src="https://lh3.googleusercontent.com/hYYJQ_ej15pu4i6A7qpsvshV4drsPbOfItyGyNjzMkikEJmjOXdhCqJmVew2CrFxAce-"
                />Peanut</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.spreely.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/Spreely.png"
                />Spreely</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.meetme.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/meetme.png"
                />MeetMe</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.deviantart.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://www.shareicon.net/data/2016/11/22/855126_circle_512x512.png"
                />DeviantArt</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.buzznet.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn.onlinewebfonts.com/svg/img_24319.png"
                />Buzznet</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.gaiaonline.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/gagia online.png"
                />Gaia Online</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.funnyordie.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/funnyordie.png"
                />Funny or Die</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.myheritage.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://www.shareicon.net/data/256x256/2016/08/07/808095_logo_512x512.png"
                />MyHeritage</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.classmates.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://cdn.onlinewebfonts.com/svg/img_24356.png"
                />Classmates</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://weheartit.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/weloveit.png"
                />We Heart It</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.ravelry.com/account/login?url='+post"
                ><img
                  class="social-img"
                  src="https://www.pngrepo.com/download/21465/ravelry-logo.png"
                />Ravelry</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.care2.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/care2.png"
                />Care2</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.wayn.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/wayn.png"
                />Wayn</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.flixster.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/Flixster.png"
                />Flixster</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.reverbnation.com/?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/ReverbNation.png"
                />ReverbNation</a
              >
              <a
                class="share-btn"
                target="_blank"
                :href="'https://www.snapfish.com/home?url='+post"
                ><img
                  class="social-img"
                  src="https://slick.isolic.net/static/social/snapfish.png"
                />Snapfish</a
              >
            </div>
          </div>
        </div>
      </div>

      <!-- ============================================================== -->
      <!-- Recent blogss -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
  </layout>
</template>
<style scoped>
.blast {
  font-size: 14px;
  color: black;
  padding-top: 10px;
}
.caption-btn {
  font-weight: bolder;
  width: 19%;
  border-radius: 4px !important;
}
.caption-select {
  margin-left: 20%;
  margin-right: 20% !important;
  width: 60%;
}
.social-img {
  max-width: 75px;
  margin: auto;
  margin-bottom: 10px;
}
#social-networks-wrap a {
  display: inline-grid;
  text-align: center;
  width: 18%;
  font-size: 15px;
  font-weight: 300;
  padding: 20px;
}

.tabs {
  position: relative;
  margin: 0 auto;
}

.tabs__item {
  display: inline-block;
  margin: 0 5px;
  padding: 10px;
  padding-bottom: 8px;
  font-size: 13px;
  letter-spacing: 0.8px;
  color: gray;
  background: #42bfc7;
  text-decoration: none;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s;
  width: 100%;
}

.tabs__item_active {
  color: black;
}

.tabs__item:hover {
  /* border-bottom: 2px solid gray; */
  color: black;
}

.tabs__item:focus {
  outline: none;
  /* border-bottom: 2px solid gray; */
  color: white;
  background: #42bfc7 !important;
  border-radius: 10px;
}

.tabs__item:first-child {
  margin-left: 0;
}

.tabs__item:last-child {
  margin-right: 0;
}

.tabs__active-line {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: black;
  transition: transform 0.4s ease, width 0.4s ease;
}
.generated-content {
  font-weight: normal;
}
.caption-button {
  border-radius: 0px 10px 10px 0px !important;
  padding: 7px;
}
.form-control {
  padding:0px !important;
  padding-left:15px !important;
}
</style>
<script>
import layout from "../components/BaseLayouts/Layout.vue";
import VueElementLoading from "vue-element-loading";
import Tabs from "vue-tabs-with-active-line";
export default {
  components: {
    layout,
    VueElementLoading,
    Tabs,
  },
  data() {
    return {
      tabs: [],
      form: {},
      contents: {},
      loading: false,
      activeContent: {},
      currentTab: "tab1",
      loadingText: '',
      post:''
    };
  },
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
    getCaption() {
      this.loading = true;
          this.loadingText = 'Getting Content Please Wait......';
      this.tabs = [];
      this.$api
        .post(this.dynamic_route("/traffic/getCaptions"), this.form)
        .then((res) => {
          console.log(res);
          this.loading = false;
          let item = res.data;
          // $("#exampleModal").modal("hide");
          this.activeContent = res.data;
          // this.$toast.success(res.data);
          for (let index = 0; index < item.length; index++) {
            var data = {
              title: "Content " + (index + 1),
              value: "tab" + (index + 1),
            };
            this.tabs.push(data);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response);
        })
        .finally(() => {
          // this.loading = false;
        });
    },

  saveContent() {
      this.loading = true;
          this.loadingText = 'Saving Content Please Wait......';
      let payload = {
        contents: this.activeContent,
        keyword: this.form.topic
      };
      this.$api
        .post(this.dynamic_route("/traffic/saveContent"), payload)
        .then((res) => {
          console.log(res);
          this.loadingText = 'Saving Content Please Wait......';
          this.loading = false;
          thios.getAllCaptions();
          this.activeContent = {};
          this.form = {};
          $("#exampleModal").modal("hide");
          this.$toast.success(res.data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getAllCaptions() {
      this.loading = true;
          this.loadingText = 'Getting Content Please Wait......';

      this.$api
        .get(this.dynamic_route("/traffic/getAllCaptions"))
        .then((res) => {
      this.false = true;

          this.contents = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getAllCaptions();
  },
};
</script>