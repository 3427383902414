<template>
    <layout>
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div style="color: #43bec7 !important" class="mr-2 mt-2">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form action="" method="post">
                                    <VueElementLoading :active="loading" spinner="ring" color="#42BFC7"
                                        text="Please wait..." />
                                    <div class="row mt-3">
                                        <div class="col-md-12 mb-3">
                                            <label for="variant" style="color: black">Number of codes</label>
                                            <div class="input-group">

                                                <input type="text" name="" class="form-control" v-model="form.number"
                                                    id="">
                                            </div>
                                            <label for="variant" style="color: black">Package</label>

                                            <div class="input-group">
                                                <select name="" id="" v-model="form.package">
                                                    <option :value="p.id" v-for="(p, i) in packages" :key="i">{{ p.name
                                                    }}</option>
                                                </select>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary"
                                    style="background:#44bdc7; border:1px #44bdc7;" @click.prevent="generateCode()">
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6 col-8 align-self-center">
                <h3 class="page-title mb-0 p-0">Dashboard</h3>
                <div class="d-flex align-items-center">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="#">Home</a></li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Dashboard
                      </li>
                    </ol>
                  </nav>
                </div>
              </div> -->
                <!-- <div class="col-md-6 col-4 align-self-center">
                          <div class="text-end upgrade-btn">
                              <a href="https://www.wrappixel.com/templates/monsteradmin/"
                                  class="btn btn-success d-none d-md-inline-block text-white" target="_blank">Upgrade to
                                  Pro</a>
                          </div>
                      </div> -->
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- Column -->
                <div class="col-12 text-center h1 mb-3 fw-400">Manage Activation Codes</div>

                <div class="col-md-12">
                    <button class="btn btn-blue mb-2 float-right" data-toggle="modal" data-target="#exampleModal">
                        Generate Codes
                    </button>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Package</th>
                                <th>Status</th>
                                <th>Used By</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(u, i) in users.data" :key="i">
                                <td>{{ i + 1 }}</td>
                                <td>{{ u.code }}</td>
                                <td>{{ u.package.name }}</td>
                                <td>
                                    <span class="badge badge-danger" v-if="u.status == 'unused'">{{ u.status }}</span>
                                    <span class="badge badge-primary" v-else>{{ u.status }}</span>
                                </td>
                                <td>{{ u.user && u.user.email }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent blogss -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
    </layout>
</template>
  
<script>
import layout from "../components/BaseLayouts/Layout.vue";
import Tabs from "vue-tabs-with-active-line";
import VueElementLoading from "vue-element-loading";

export default {
    components: {
        layout,
        Tabs,
        VueElementLoading,
    },
    data: () => ({
        form: {
            loading: false,
        },
        loading: false,
        users: {},
        current: {},
        packages: {}
    }),
    methods: {
        getUserProfile() {
            this.form.loading = true;
            this.$api
                .get(this.dynamic_route("/codes"))
                .then((res) => {
                    this.users = res.data;
                })
                .catch((err) => {
                    this.form.loading = false;
                    console.log(err.response);
                })
                .finally(() => {
                    // this.loading = false;
                });
        },
        createuser() {
            this.$router.push("/register");
        },
        getUser(data) {
            this.current = data
        },
        generateCode() {
            this.loading = true;
            this.$api
                .post(this.dynamic_route("/codes"), this.form)
                .then((res) => {
                    this.loading = false;
                    if (res.data.code == 200) {
                        this.$toast.success(res.data.message);
                        $('#exampleModal').modal('hide');
                        location.reload();
                    } else {
                        this.$toast.error(res.data.message);
                    }

                })
                .catch((err) => {
                    this.loading = false;

                    console.log(err.response);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getPackages() {
            this.loading = true;
            this.$api
                .get(this.dynamic_route("/packages"))
                .then((res) => {
                    this.packages = res.data;
                })
                .catch((err) => {
                    console.log(err.response);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getUserProfile();
        this.getPackages();
    },
};
</script>
  