<template>
  <layout>
    <!-- ============================================================== -->
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="page-breadcrumb">
      <div class="row align-items-center">
        <!-- <div class="col-md-6 col-8 align-self-center">
              <h3 class="page-title mb-0 p-0">Dashboard</h3>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div> -->
        <!-- <div class="col-md-6 col-4 align-self-center">
                        <div class="text-end upgrade-btn">
                            <a href="https://www.wrappixel.com/templates/monsteradmin/"
                                class="btn btn-success d-none d-md-inline-block text-white" target="_blank">Upgrade to
                                Pro</a>
                        </div>
                    </div> -->
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Sales chart -->
      <!-- ============================================================== -->
      <div class="row">
        <!-- Column -->
        <div class="col-12 text-center h1 mb-3 fw-400">
          Back<span class="text-blue">links</span>
          <br>
          <button class="btn btn-blue mt-3" @click="createBacklink">Create Backlinks</button>
          
        </div>
        <div class="col-12 text-center h1 mb-3 fw-400">
        </div>
        <!-- <div class="col-12">
              <div class="jumbotron text-center">
                  <div class="row">
                    
                      <div class="col-11">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Enter website address" aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                      </div>
                      <div class="col-1"> <button class="btn btn-blue">Check</button></div>
                     
                  </div>
              </div>
            </div> -->
        <!-- <div class="col-12">
                <iframe src="http://website-review.test" frameborder="0" class="w-100" style="height:100vh !important;"></iframe>
              </div> -->
      </div>

      <!-- ============================================================== -->
      <!-- Recent blogss -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
  </layout>
</template>
<script>
import layout from "../components/BaseLayouts/Layout.vue";
export default {
  components: {
    layout,
  },
  methods: {
    createBacklink() {
      window.open(
      "https://profitenigma.com/backlink-creator-tools/backlink-maker",
      "_blank" // <- This is what makes it open in a new window.
    );
    }
  }
};
</script>