<template>
  <layout>
    <div>
     <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
          <div class="row align-items-center">
            <!-- <div class="col-md-6 col-8 align-self-center">
              <h3 class="page-title mb-0 p-0">Dashboard</h3>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-4 align-self-center">
                        <div class="text-end upgrade-btn">
                            <a href="https://www.wrappixel.com/templates/monsteradmin/"
                                class="btn btn-success d-none d-md-inline-block text-white" target="_blank">Upgrade to
                                Pro</a>
                        </div>
                    </div> -->
          </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
         <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Sales chart -->
          <!-- ============================================================== -->
          <div class="row">
            <!-- Column -->
            <div class="col-12 text-center h1 mb-3 fw-400">
                    Golden Keyword <span class="text-blue">Finder</span>
          <p class="blast">Please, go to Settings then click on API keys and input your API key and search engine ID to access the Golden Keyword Finder.</p>

            </div>
            <div class="col-12">
               <VueElementLoading
                        :active="loading"
                        spinner="ring"
                        color="#42BFC7"
                        text="Loading.."
                        
                    />
              <div class="jumbotron text-center">
                <form action="" id="formSubmit" @submit.prevent="getResult">
                  <div class="row">
                    <div class="col-11">
                      <div class="input-group mb-3">
                          <textarea name="" id="" cols="30" rows="10" class="form-control" v-model="form.keyword"></textarea>
                      </div>
                    </div>
                    <div class="col-1"> <button class="btn btn-blue">Check</button></div>
                </div>
                </form>
                
                <div id="results"></div>
              </div>
              <p style="font-weight:bolder; color:black;"><span style="color:red; font-size:20px;">Important:</span> The keywords you put in there needs to have a search volume on Google, if one of the keywords you add there has no search volume then the app will result in a blank page. Also we recommend a max of 3-5 keywords per search / per check!</p>
              <div class="my-5">
                 <table class="table table-bordered" v-if="keywordResult">
                   <thead>
                     <tr>
                        <th align="left">Keyword</th>
                        <!-- <th align="center">Broad</th> -->
                        <th align="center">Search Volume</th>
                        <th align="center">AllInTitle</th>
                        <th align="center">Golden</th>
                        <!-- <th align="center">AllInAnchor</th>
                        <th align="center">AllInText</th> -->
                    </tr>
                   </thead>
                   <tbody>
                     <tr v-for="(keyword, i) in keywordResult" :key="i">
                       <td>{{i}}</td>
                       <td v-for="(r, b) in keyword" :key="b" 
                       :class="{'bg-success text-white' : b=='golden' && Number(r) <=8,
                                'bg-warning text-white' : b=='golden' && (Number(r) > 8 && Number(r) <= 25),
                                'bg-danger text-white' : b=='golden' && Number(r) > 25,
                              }"
                        >{{r}}{{(b==2)?2:""}} </td>
                     </tr>
                   </tbody>
                    
                 </table>
                <form @submit.prevent="download" class="text-center my-4" enctype="multipart/form-data">
                  <input name="download" id="download" type="hidden" 
                    v-model="encryptedData" 
                    size="30">
                  <input class="btn btn-blue" type="submit" value="Export to Excel">
                </form>
                </div>
            </div>
           
          </div>
         
          <!-- ============================================================== -->
          <!-- Recent blogss -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
    </div>
  </layout>

</template>
<style>
  .blast {
  font-size: 14px;
  color: black;
  padding-top: 10px;
}
</style>
<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading'
import layout from "../components/BaseLayouts/Layout.vue";
export default {
    components: {
        layout,
        VueElementLoading
        },
        data () {
          return  {
            form : {},
            loading : false,
            keywordResult : {},
            csvForm : '',
            encryptedData : 'YToyOntpOjA7czo2MjoiS2V5d29yZAlCcm9hZAlFeGFjdAlBbGxJblRpdGxlCUFsbEluVVJMCUFsbEluQW5jaG9yCUFsbEluVGV4dAoiO2k6MTtzOjcxOiJkaWdpdGFsCTEyNzQwMDAwMDAwCTUyMTAwMDAwMDAJMTA2MDAwMDAwCTcyNzAwMDAwCTM5MjAwMDAwMAk1ODQwMDAwMDAwCiI7fQ=='
          }
        },
        methods : {
          getResult(){
            this.loading = true
           this.$api.post(this.dynamic_route("/keyword-checker/all"), this.form)
            .then((res)=>{
              this.keywordResult = res.data.data
              this.encryptedData = res.data.encryptedData
              
              if(res.data.status  == false){
                console.log("demi");
                  this.$toast.error(res.data.message)
              }
            }).catch(()=>{
              // console.log(8765)
              
            }).finally(()=>{
              this.loading = false;
            })
          },
          download(){
             this.$api.post(this.dynamic_route("/keyword-checker/download"), {
               download : document.getElementById('download').value,
               responseType: 'blob'
             })
            .then((res)=>{
              var myRes = res.data
              let fileUrl = window.URL.createObjectURL(new Blob([myRes]));
              let fileLink = document.createElement('a');
              fileLink.href = fileUrl;
              fileLink.setAttribute('download', 'results.xls');
              document.body.appendChild(fileLink)

              fileLink.click();
              
            }).catch(err=>{
              console.log(err)
            }).finally(()=>{
              this.loading = false;
            })
          },
          paint_field(n){
            return Number(n) <= 0.25?true:false;
          }
        }
}
</script>