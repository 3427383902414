<template>
  <div>
    <div class="col-12 my-4">
      <VueElementLoading :active="loading" spinner="ring" color="#42BFC7" text="Loading.." />
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div style="color: #43bec7 !important" class="mr-2 mt-2">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form action="" method="post">
                <VueElementLoading :active="loading" spinner="ring" color="#42BFC7" text="Please wait..." />
                <div class="row mt-3">
                  <div class="col-md-12 mb-3">
                    <label for="variant" style="color: black">Have a License code? Enter Below</label>
                    <div class="input-group">

                      <input type="text" name="" class="form-control" v-model="form.license" id=""
                        placeholder="License Code">
                    </div>

                  </div>


                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" style="background:purple; border:1px purple; color:white;"
                @click.prevent="generateCode()">
                Buy License Code
              </button>
              <button type="button" class="btn btn-primary" style="background:#44bdc7; border:1px #44bdc7;"
                @click.prevent="getPackage()">
                Activate
              </button>
            </div>
          </div>
        </div>
      </div>
      <table class="table border table-bg fw-400">
        <thead style="background-color: #f5f4f4; font-size: 20px">
          <td>Product Name</td>
          <td>Action</td>
        </thead>
        <tbody>
          <tr v-for="(p, i) in packages" :key="i">
            <td>{{ p.name }}</td>
            <td>
              <button class="btn btn-blue" v-if="!p.users.length" data-toggle="modal" data-target="#exampleModal"
                @click="setpackage(p)">
                Get This
              </button>
              <button class="btn btn-secondary btn-disabled" v-else disabled>
                &nbsp; &nbsp; ✅ &nbsp; &nbsp;
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading,
  },
  data() {
    return {
      packages: {},
      loading: false,
      currentPackage: {},
      form: {
        license: ''
      }
    };
  },
  methods: {
    getPackages() {
      this.loading = true;
      this.$api
        .get(this.dynamic_route("/packages"))
        .then((res) => {
          this.packages = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setpackage(data) {
      this.currentPackage = data;
    },
    getPackage() {
      this.loading = true;
      let token = JSON.parse(localStorage.getItem("authInfo"))[0].authToken;
      let payload = {
        tablels: token,
        package: this.currentPackage.id,
        license: this.form.license
      };
      this.$api
        .post(this.dynamic_route("/packages"), payload)
        .then((res) => {
          if (res.data.status == 200) {
            this.$toast.success(res.data.message);

            if (res.data.token) {
              let authInfo = [
                {
                  authToken: res.data.token,
                  userData: res.data.data,
                  perm: res.data.perm,
                  sidebar: res.data.sidebar,
                },
              ];
              localStorage.setItem("authInfo", JSON.stringify(authInfo));
            }
          } else {
            this.$toast.error(res.data.message);
          }


          this.getPackages();
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    generateCode() {
      window.open('https://rebrand.ly/rankpro', '_blank');
    }
  },
  mounted() {
    this.getPackages();
  },
};
</script>